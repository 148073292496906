import getLocaleAwareNumberSeparators from '@/helpers/get-locale-aware-number-separators'

const thounsandsSeparator = getLocaleAwareNumberSeparators().thousand
const decimalSeparator = getLocaleAwareNumberSeparators().decimal

/**
 *
 * @param {Number} num
 * @param {Number} precision
 * @returns Number
 */
const toFixedNoRound = function (num, precision = 1) {
  const factor = Math.pow(10, precision)
  return Math.floor(num * factor) / factor || 0
}

/**
 *
 * @param {Number} number
 * @param {Number} decimalPoint
 * @returns {String} like ',12'
 */
const getDecimalPartOfNumber = function (num, decimalPoint) {
  // get decimal part of number
  return String(num.toFixed(decimalPoint)).split('.')[1] || 0
}

/**
 *
 * @param {Number} num
 * @param {Number} decimal
 * @returns {String} like 1.234,56M locale aware thousand seperated
 */
const localizeNumber = function (num, decimal, showThousandSeparator) {
  const decimalPart = decimal ? decimalSeparator + decimal : ''
  num = showThousandSeparator
    ? Intl.NumberFormat(navigator.language).format(num)
    : num
  if (decimal) {
    return (
      String(num).replace(decimalSeparator, thounsandsSeparator) + decimalPart
    )
  }
  return num
}

/**
 *
 * @param {Number} num value to be converted
 * @param {Number} abbrCount example: if abbrCount is 1, returns 1.1K instead of 1K (value: 1100)
 * @param {Number} decimalPoint decimal count
 * @param {String} letter K, M, B. divider and suffix letter. This affects calculation.
 * @returns {String} formatted number like 2.2M
 */
const getNumber = function (
  num,
  abbrCount,
  decimalPoint,
  letter,
  hideAbbrLetter,
  showThousandSeparator,
) {
  const lettersObj = {
    K: 1000,
    M: 1000000,
    B: 1000000000,
    t: 1000000000000,
    q: 1000000000000000,
  }

  const letterString = hideAbbrLetter ? '' : letter

  if (abbrCount) {
    if (decimalPoint) {
      const decimal = getDecimalPartOfNumber(num, decimalPoint)
      return (
        localizeNumber(
          toFixedNoRound(num / lettersObj[letter], abbrCount),
          decimal,
          showThousandSeparator,
        ) + letterString
      )
    }
    return (
      localizeNumber(
        toFixedNoRound(num / lettersObj[letter], abbrCount),
        null,
        showThousandSeparator,
      ) + letterString
    )
  }

  if (decimalPoint) {
    // get decimal part of number
    const decimal = getDecimalPartOfNumber(num, decimalPoint)

    // add decimal part and letter (K, M, B) at the end of the number
    return (
      localizeNumber(
        Math.trunc(num / lettersObj[letter]),
        decimal,
        showThousandSeparator,
      ) + letterString
    )
  }
  return (
    localizeNumber(
      Math.round(num / lettersObj[letter]),
      null,
      showThousandSeparator,
    ) + letterString
  )
}

/**
 *
 * @param {Number} num
 * @param {Number} abbrCount Abbreviation digit amount after dot. 3 will convert 1234000 to 1.234M
 * @param {Number} decimalPoint Decimal count
 * @param {Number} forcedAbbrLetter Force formatter to abbreviate for given letter. 'M' will convert 1000 to 0.001M
 * @param {Boolean} hideAbbrLetter to hide abbr letter
 */
const abbr = function (
  num,
  abbrCount = 0,
  decimalPoint = 0,
  forcedAbbrLetter = null,
  hideAbbrLetter = false,
  showThousandSeparator = true,
) {
  if (!num) {
    return num
  }
  if (typeof num === String) {
    num = Number(num)
  }

  let letter = forcedAbbrLetter

  // if forcedAbbrLetter is not provided, find the letter from number's length
  // in some scenarios, we need to provide abbr letter before calculations, we need numbers like 0.001M
  // so that our getNumber method can understand divider count from that letter and calculates it
  if (!letter) {
    // check number's length and find the correct abbreviation letter
    const integer = Math.round(num)
    const numLength = String(integer).length

    if (numLength < 7) {
      letter = 'K' // 1000: 1K
    } else if (numLength < 10) {
      // million
      letter = 'M' // 1000000: 1M
    } else if (numLength < 13) {
      // billion
      letter = 'B' // 1000000000: 1B
    } else if (numLength < 16) {
      // trillion
      letter = 't' // 1000000000000: 1t
    } else {
      // quadrillion
      letter = 'q' // 1000000000000000: 1q
    }
  }

  if (letter) {
    return getNumber(
      num,
      abbrCount,
      decimalPoint,
      letter,
      hideAbbrLetter,
      showThousandSeparator,
    )
  }

  return num
}

export default abbr
